import "./registerServiceWorker";
import "@mdi/font/css/materialdesignicons.css";
import "./assets/index.css";

import { createApp } from "vue";
import router from "@/router";
import App from "@/App.vue";

createApp(App)
  .use(router)
  .mount("#app");
