import { reactive, toRefs } from "vue";

import { authService } from "@/services/auth.service";
import { User } from "@/types/User";
import { Credential } from "@/types/Auth";

interface ISessionState {
  user: User | null;
  loading: boolean;
  error: boolean;
}

const state = reactive<ISessionState>({
  user: null,
  loading: false,
  error: false
});

export function useSession() {
  async function login(credential: Credential, save?: boolean) {
    try {
      state.loading = true;
      state.error = false;
      state.user = null;

      state.user = await authService.login(credential);
      sessionStorage.setItem("SESSION", JSON.stringify(state.user));
      // Save credential if needed
      save && localStorage.setItem("CREDENTIAL", JSON.stringify(credential));
    } catch {
      state.error = true;
    } finally {
      state.loading = false;
    }
  }

  async function logout() {
    try {
      await authService.logout();
      sessionStorage.removeItem("SESSION");
      localStorage.removeItem("CREDENTIAL");
    } catch {
      // PASS
    }
  }

  async function getSession() {
    try {
      state.loading = true;
      state.error = false;

      state.user = await authService.currentUser();
      sessionStorage.setItem("SESSION", JSON.stringify(state.user));
    } catch {
      state.error = true;
    } finally {
      state.loading = false;
    }
  }

  return {
    ...toRefs(state),
    login,
    logout,
    getSession
  };
}
