import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import responsiveRoute from "@/router/responsiveRoute";
import { authService } from "@/services/auth.service";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/:id",
    name: "home",
    component: responsiveRoute({
      mobile: () => import("@/views/mobile/MHome.vue"),
      default: () => import("@/views/desktop/Home.vue")
    }),
    meta: {
      title: "Главная"
    }
  },

  {
    path: "/:id/attendance",
    name: "attendance",
    component: responsiveRoute({
      mobile: () => import("@/views/mobile/MAttendance.vue"),
      default: () => import("@/views/desktop/Attendance.vue")
    }),
    meta: {
      title: "Посещение"
    }
  },

  {
    path: "/:id/newspaper",
    name: "newspaper",
    component: responsiveRoute({
      mobile: () => import("@/views/mobile/MNewspaper.vue"),
      default: () => import("@/views/desktop/Newspaper.vue")
    }),
    meta: {
      title: "Новости"
    }
  },

  {
    path: "/:id/profile",
    name: "profile",
    component: responsiveRoute({
      mobile: () => import("@/views/mobile/MProfile.vue")
    })
  },

  {
    path: "/login",
    name: "login",
    component: () => import("@/views/desktop/Login.vue")
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  const excludePaths = ["/login"];

  if (excludePaths.includes(to.path)) return next();

  const storeRecord = localStorage.getItem("CREDENTIAL");
  if (storeRecord) {
    try {
      const credentials = JSON.parse(storeRecord);

      authService
        .currentUser()
        .then(() => next())
        .catch(() => {
          authService
            .login(credentials)
            .then(() => next())
            .catch(() => router.push({ name: "login" }));
        });
    } catch {
      return next();
    }
  }
});

export default router;
