import { AxiosInstance } from "axios";

import { api } from "@/services/api.service";
import { Kid } from "@/types/User";

class KidsService {
  private readonly api: AxiosInstance;

  constructor(api: AxiosInstance) {
    this.api = api;
  }

  public getParentKids(): Promise<Kid[]> {
    return this.api.get(`/area/users`);
  }
}

export const kidsService = new KidsService(api);
