import { reactive, toRefs } from "vue";
import { head } from "lodash";

import { kidsService } from "@/services/kids.service";
import { Kid } from "@/types/User";

interface IKidsState {
  kids: Kid[] | [];
  loading: boolean;
  error: boolean;
}

const state = reactive<IKidsState>({
  kids: [],
  error: false,
  loading: false
});

export function useKids() {
  async function loadKids() {
    try {
      state.loading = true;
      state.error = false;
      state.kids = [];

      state.kids = await kidsService.getParentKids();
    } catch {
      state.error = true;
    } finally {
      state.loading = false;
    }
  }

  function getFirst() {
    return head(state.kids);
  }

  return {
    ...toRefs(state),
    loadKids,
    getFirst
  };
}
