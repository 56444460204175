
import { defineComponent, onBeforeMount } from "vue";
import { useRouter } from "vue-router";

import { useKids } from "@/store/kids.store";
import { useSession } from "@/store/session.store";

export default defineComponent({
  name: "Home",

  setup() {
    const router = useRouter();
    const sessionStore = useSession();
    const kidsStore = useKids();

    onBeforeMount(async () => {
      try {
        const credential = JSON.parse(localStorage.getItem("CREDENTIAL") || "");

        await sessionStore.login(credential);
        await kidsStore.loadKids();

        const firstKid = kidsStore.getFirst();

        firstKid
          ? await router.replace({ name: "home", params: { id: firstKid.id } })
          : null;
      } catch {
        await router.replace({ name: "login" });
      }
    });
  }
});
