import axios, { AxiosError } from "axios";
import router from "@/router";

const api = axios.create({
  baseURL: location.origin + "/api_kg/v1",
  timeout: 15000
});

api.interceptors.response.use(({ data }) => data);

// api.interceptors.response.use(undefined, async (error: AxiosError) => {
//   if (!error.response) return;
//
//   const { config, status } = error.response;
//
//   if (!config.url) return;
//
//   if (status === 401 && !config.url.endsWith("/auth"))
//     await router.push("/login");
//
//   throw error;
// });

export { api };
