import { Component } from "vue";

// Breakpoint when need load mobile view if exist
const RESPONSIVE_BREAKPOINT = 768;

interface ResponsiveRoutesMap {
  mobile?: () => Component;
  default?: () => Component;
}

export default function responsiveRoute(map: ResponsiveRoutesMap) {
  return function getResponsiveView() {
    const screenWidth = document.documentElement.clientWidth;

    // Check if needed resolve mobile view component
    const needMobileView = screenWidth < RESPONSIVE_BREAKPOINT;

    if (needMobileView && map.mobile) return Promise.resolve(map.mobile());
    if (map.default) return Promise.resolve(map.default());

    return Promise.reject("Not found mobile or default component for render.");
  };
}
