import qs from "qs";
import { AxiosInstance } from "axios";

import { api } from "@/services/api.service";

import { User } from "@/types/User";
import { Credential } from "@/types/Auth";

class AuthService {
  private readonly api: AxiosInstance;

  constructor(api: AxiosInstance) {
    this.api = api;
  }

  public login(credential: Credential): Promise<User> {
    if (!(credential.login && credential.pwd))
      throw new Error("Login or password must be exist!");

    const query = qs.stringify({
      login: credential.login.trim(),
      pwd: credential.pwd.trim()
    });

    const headers = {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
    };

    return this.api.post(`/auth?${query}`, {}, { headers });
  }

  public currentUser(): Promise<User> {
    return this.api.get("/current-user");
  }

  public logout(): Promise<void> {
    return this.api.get("/logout");
  }
}

export const authService = new AuthService(api);
